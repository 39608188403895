@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #4F4F4F;
  height: 100%;
  height: 100vh;
  background: #4F4F4F;
  overflow: hidden;
}

button::-moz-focus-inner {
  border: 0;
}

.Button {
  flex: 1;
  margin: 5px;
  padding: 5px;
  font-size: 18px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.Button--green {
  background: #0f9615;
  color: #fff;
}

.Button--blue {
  background: #1c8dd5;
  color: #fff;
}

.Button--red {
  background: #960f0f;
  color: #fff;
}

.Button:disabled {
  background: #b5b5b5;
  color: #fff;
  cursor: none;
}

#root{
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}


.Alert{

}
.Alert--error{
  flex: 1 100%;
  margin: 5px;
  padding: 5px;
  font-weight: bold;
  text-align: center;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 5px;
}
