@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.App {

}

.App-container {
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
}

.Header{
  width: 100%;
  height: 60px;
  background-color: #dfdfdf;
}

.Header-icon{
  margin: 5px;
  display: inline-block;
  color: #000;
}

/* .Header-icon--right{
  float: right;
} */

.Header-icon svg{
  height: 40px;
  padding: 5px 0;
}

.Team{
  color: #fff;
  flex: 1 0 200px;
  padding: 5px;
  margin: 5px;
  text-shadow: 1px 1px 2px black;
  border-radius: 3px;
  background: rgba(255,255,255,0.3);
}

.TeamHeader{
  display: flex;
  flex-flow: row nowrap;
}

.TeamHeader__name, .TeamHeader__team{
  color: #fff;
  background: none;
  border: none;
  border-bottom: 4px solid rgba(0,0,0,.5);
  font-size: 15px;
  margin: 5px;
}

.TeamHeader__name{
  flex: 1 1;
}

.TeamHeader__remove{
  flex: 1 1;
  max-width: 25px;
  line-height: 40px !important;
}

.TeamList {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.TeamList__controls{
  flex: 0 0 100%;
  display: flex;
}

.Match-Player{
  color: #fff;
  flex: 1 1;
  margin: 5px;
  text-shadow: 1px 1px 3px black;
  border-radius: 5px;
}

.Match-PlayerHeader{
  display: flex;
  flex-flow: row nowrap;
}

.Match-PlayerHeader__name{
  flex: 1 1;
  color:#fff;
  border: none;
  font-size: 10px;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  background: rgba(0,0,0,0.5);
}

.Match-PlayerColors{
  margin: 5px;
  margin-top: 0px;
}

.Symbols {
  flex: 1 1;
  width: 100 %;
  display: flex;
  background: rgba(0,0,0,0.5);
  border-radius: 5px;
  padding: 5px;
}

.Symbol {
  flex: 1 1;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  margin: 3px;
  font-size: 12px;
  min-width: 15px;
}

.Symbol__image {
  opacity: 0.7;
  width: 90%;
  max-height: 20px;
  max-width: 20px;
}


.Symbols.small  .Symbol__image{
  height: 15px;
}

.Dice{
  height: 90px;
  text-align: center;
  width: 230px;
  margin: 0 auto;
}

.Dice-number{
  padding: 5px;
  color: #FFFFFF;
  text-shadow: 1px 1px 2px black;
  font-size: 40px;
}

.Dice-button{
  background: rgba(0,0,0,0.2);
  border: none;
  color: #fff;
  text-shadow: 1px 1px 2px black;
  padding: 5px 10px;
}

.Counter{
  height: 70px;
  width: 100%;
  margin: 10px auto;
  font-size: 50px;
  line-height: 70px;
  color: #fff;
  display: flex;
}

.Counter__side{
  flex: 1 0 50px;
  width: 50px;
  height: 70px;
  text-align: center;
  background: rgba(0,0,0,0);
  border: none;
  color: #fff;
  text-shadow: 1px 1px 2px black;
}


.Counter__side svg{
  height: 50px;
  cursor: pointer;
}


.Counter__counter{
  flex: 1 0 110px;
  margin: 0px 5px;
  text-align: center;
  width: 90px;
  padding: 0 10px;
  font-size: 70px;
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Counter__counter.red{
  color: #ff0000;
}

.Match-Control{

}

.Match-Control__content {
  width: 100%;
  display: flex;
}

.Match-Control__tabs-item{
  border-top: 2px rgba(0, 0, 0, 0.3) solid;
  flex: 1 1;
  color: #fff;
  text-align: center;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
}

.Match-Control__tabs-item.active{
  border-top: 2px #dfdfdf solid;

}

.Match-Team{
  color: #fff;
  flex: 1 0 40%;
  padding: 5px;
  margin: 5px;
  text-shadow: 1px 1px 2px black;
  border-radius: 3px;
  background: rgba(255,255,255,0.3);
}

.Match-TeamHeader{
  display: flex;
  flex-flow: row nowrap;
}

.Match-TeamHeader__name{
  flex: 1 1;
  color: #fff;
  border: none;
  font-size: 15px;
  padding: 5px;
  border-radius: 5px;
  line-height: 23px;
  background: rgba(0,0,0,0.5);
}

.Match-TeamHeader__score{
  flex: 0 1 30px;
  color: #fff;
  border: none;
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  line-height: 23px;
  background: rgba(10,0,0,0.5);
  margin-left: 5px;
  text-align: center;
}

.Match-TeamHeader__colors{
  flex: 0 1 30px;
  margin-left: 5px;
}

.Match-TeamHeader__leader{
  width: 20px;
  margin: 3px;
  float: right;
}

.Match-TeamPlayers{
  width: 50%;
  float: right;
  display: flex;
  flex-flow: row wrap;
}

.Match {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.Match__controls {
  flex: 0 0 100%;
  display: flex;
}


.Match.emperor .Match-Team {
  flex: 1 1 30%; 
}

.Player{
  color: #fff;
  flex: 1 0 200px;
  padding: 5px;
  margin: 5px;
  text-shadow: 1px 1px 2px black;
  border-radius: 5px;
}

@media screen and (min-width: 420px) {
  .Player {
    flex: 1 1 40%;
  }
}

.PlayerHeader{
  display: flex;
  flex-flow: row nowrap;
}

.PlayerHeader__name, .PlayerHeader__team{
  color: #fff;
  background: rgba(0,0,0,.5);
  border: none;
  font-size: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
}

.PlayerHeader__name{
  flex: 1 1;
}

.PlayerHeader__team{
  flex: 1 1;
  /* max-width: 50%;/ */
}

.PlayerHeader__remove{
  flex: 1 1;
  max-width: 25px;
  line-height: 40px !important;
}

.PlayerColors{
  width: 100%;
  display: flex;
  background: rgba(0,0,0,0.5);
  border-radius: 5px;
}

.PlayerColors__item{
  border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
  flex: 1 1;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
  cursor: pointer;
}

.PlayerColors__item.active{
  border-bottom: 5px rgba(255, 255, 255, 1) solid;
  color: rgba(255, 255, 255, 1);
}

.PlayerColors__item.active{
  border-bottom: 5px rgba(255, 255, 255, 1) solid;
  color: rgba(255, 255, 255, 1);
}
.PlayerColors__item-image{
  opacity: 0.7;
  margin: 0 0 0 5px;
}

.PlayerList {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.PlayerList__controls{
  flex: 0 0 100%;
  display: flex;
}

.MatchType {
  text-align: center;
  color: #dfdfdf;
  width: 100%;
  margin: 0 auto;
}

.MatchType > svg{
  margin-top: 20px;
  width: 400px;
  max-width: 80%;
}

.MatchType > svg .castle {
  color: #017702;
}

.Format{
  background-color: rgba(255,255,255,0.5);
  margin: 10px;
  color: #000;
  font-weight: bold;
  font-size: 20px;
  line-height: 55px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  max-width: 400px;
  margin: 10px auto;
}

.Format__icon{
  width: 55px;
  height: 55px;
  margin: 5px 10px;
}

.Format__name{
  margin: 5px;
}

.MatchSort{
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  text-align: center;
  color: #dfdfdf;
  margin: 0 auto;
}

.MatchSort__container{
  display: flex;
  flex-flow: row wrap;
}

.MatchSort__team{
  flex: 1 1 100px;
  height: 50px;
  margin: 5px;
  padding: 10px;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 2px black;
  background: rgba(255,255,255,0.3);
}

.MatchSort__team--first{
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 30px);
  background: rgba(20, 200, 20, 0.6);
  height: 60px;
}

.MatchSort__team-name{
}

.MatchSort__team-score{
  font-size: 25px;
  margin-top: 5px;
}

.MatchRoundWinner{
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  text-align: center;
  color: #dfdfdf;
  margin: 0 auto;
}

.MatchRoundWinner__container{
  display: flex;
  flex-flow: row wrap;
}

.MatchRoundWinner__team{
  flex: 1 1 100px;
  height: 50px;
  margin: 5px;
  padding: 10px;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 2px black;
  background: rgba(255,255,255,0.3);
}

.MatchRoundWinner__team.selected{
  background: rgba(20, 200, 20, 0.6);
}

.MatchRoundWinner__team-name{
}

.MatchRoundWinner__team-score{
  font-size: 25px;
  margin-top: 5px;
}

.MatchLog{
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  text-align: center;
  color: #dfdfdf;
  margin: 0 auto;
}

.MatchLog__container{
  display: flex;
  flex-flow: row wrap;
}

.MatchLog__team{
  flex: 1 1 100px;
  height: 50px;
  margin: 5px;
  padding: 10px;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 2px black;
  background: rgba(255,255,255,0.3);
}

.MatchLog__team.selected{
  background: rgba(20, 200, 20, 0.6);
}

.MatchLog__team-name{
}

.MatchLog__team-score{
  font-size: 25px;
  margin-top: 5px;
}



.MatchLogItem {
  display: flex;
  flex: 1 1 450px;
  height: 50px;
  margin: 5px;
  padding: 10px;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 2px black;
  background: rgba(255,255,255,0.3);
}

.MatchLogItem > div {
  line-height: 40px;
}

.MatchLogItem__content {
  flex: 1 1;
  font-weight: bold;
  font-size: 25px;
}

.MatchLogItem__match {
  flex: 0 1 40px;
  font-weight: bold;
  font-size: 25px;
  border-right: 1px solid rgba(255,255,255,0.3);
  padding: 5px;
  padding-left: 0;
}

.MatchLogItem__action {
  flex: 0 1 70px;
  display: flex;
  padding: 5px;
  margin-left: 10px;
}

.MatchLogItem__action svg {
  flex: 1 1;
  width: 40px;
  height: 40px;
}

.MatchLogItem__action .value {
  flex: 1 1;
  width: 20px;
  font-size: 25px;
}

.MatchLogItem__action.red{
  color: #f00;
}

.MatchLogItem__action.green{
  color: #0f0;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #4F4F4F;
  height: 100%;
  height: 100vh;
  background: #4F4F4F;
  overflow: hidden;
}

button::-moz-focus-inner {
  border: 0;
}

.Button {
  flex: 1 1;
  margin: 5px;
  padding: 5px;
  font-size: 18px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.Button--green {
  background: #0f9615;
  color: #fff;
}

.Button--blue {
  background: #1c8dd5;
  color: #fff;
}

.Button--red {
  background: #960f0f;
  color: #fff;
}

.Button:disabled {
  background: #b5b5b5;
  color: #fff;
  cursor: none;
}

#root{
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}


.Alert{

}
.Alert--error{
  flex: 1 1 100%;
  margin: 5px;
  padding: 5px;
  font-weight: bold;
  text-align: center;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 5px;
}

